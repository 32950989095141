import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import TwoColumnSectionWithImage from "../../../components/custom-widgets/two-column-section-with-image";
import List from "../../../components/custom-widgets/list";
import MobileAppDownloadBtn from "../../../components/mobile-online-banking/mobile-app-download-btn";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

// Helpers
import showMktoForm from "../../../helpers/showMktoForm";

const TreasuryManagementServices = () => {
  const title = "Commercial Treasury Management Services";
  const description =
    "From online banking to merchant services, WaFd Bank has all of the treasury management tools to help your business succeed. Learn more here.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/hero-treasury-management-12302022-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/hero-treasury-management-12302022-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/hero-treasury-management-12302022-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/hero-treasury-management-12302022-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/hero-treasury-management-12302022-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/hero-treasury-management-12302022-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/hero-treasury-management-12302022-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      wafdTreasuryMobileApp: file(relativePath: { eq: "wafd-treasury-mobile-01-500.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 300, height: 500, layout: CONSTRAINED)
        }
      }
      wafdTreasuryPaymentMobileApp: file(relativePath: { eq: "wafd-treasury-mobile-payment-01-500.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 300, height: 500, layout: CONSTRAINED)
        }
      }
      card01: file(relativePath: { eq: "cards/thumbnail-deposit-accounts-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card02: file(relativePath: { eq: "cards/thumbnail-security-and-fraud-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card03: file(relativePath: { eq: "cards/thumbnail-treasury-express-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card04: file(relativePath: { eq: "cards/thumbnail-treasury-prime-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card05: file(relativePath: { eq: "cards/thumbnail-receivables-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card06: file(relativePath: { eq: "cards/thumbnail-payables-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/treasury-management-services"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-treasury-management-12302022.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "treasury-management-services-hero",
    chevronBgClass: "bg-info",
    ...getHeroImgVariables(imgData),
    altText: "Senior businessman on mobile phone",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Work Smarter, Not Harder"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Treasury Management"
    }
  ];

  const cardsData = {
    sectionClass: "",
    hasGrowEffect: true,
    cards: [
      {
        id: "treasury-management-card-01",
        url: "/commercial-banking/treasury-management-services/deposit-accounts",
        title: "Deposit Accounts",
        text: "Your checking account is more than a number. It is your connection to cash, payments, receivables and more.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card01.childImageSharp.gatsbyImageData,
          altText: "Woman depositing check with a mobile phone"
        }
      },
      {
        id: "treasury-management-card-02",
        url: "/commercial-banking/treasury-management-services/security-fraud",
        title: "Security & Fraud",
        text: "Prevention is the best approach.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card02.childImageSharp.gatsbyImageData,
          altText: "Two business men working on laptop in the office"
        }
      },
      {
        id: "treasury-management-card-03",
        url: "/commercial-banking/treasury-management-services/express",
        title: "WAFD Treasury Express",
        text: "A simplified approach to treasury.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card03.childImageSharp.gatsbyImageData,
          altText: "Businesswoman working on laptop at office desk"
        }
      },
      {
        id: "treasury-management-card-04",
        url: "/commercial-banking/treasury-management-services/prime",
        title: "WAFD Treasury Prime",
        text: "WAFD Treasury Prime is a robust commercial online banking platform with a personalized and easy to use experience.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card04.childImageSharp.gatsbyImageData,
          altText: "Happy businesswoman laughing in her office"
        }
      },
      {
        id: "treasury-management-card-05",
        url: "/commercial-banking/treasury-management-services/receivables",
        title: "Receivables",
        text: "Processing receivables your way is easy with all the options WaFd Bank has to offer.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card05.childImageSharp.gatsbyImageData,
          altText: "Woman working on laptop in an office"
        }
      },
      {
        id: "treasury-management-card-06",
        url: "/commercial-banking/treasury-management-services/payables",
        title: "Payables",
        text: "Streamline your payments process with WAFD Treasury Prime's easy to use payments feature.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card06.childImageSharp.gatsbyImageData,
          altText: "Businessman in a wheelchair at a desk"
        }
      }
    ]
  };

  const treasuryPrimeVideoData = {
    title: "WaFd Bank Commercial Treasury Prime Review - By Bloodworks Northwest CEO",
    vimeoId: "491416518",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const VideoContent = () => {
    return (
      <>
        <h2>WaFd Bank Commercial Treasury Prime Review - By Bloodworks Northwest President & CEO, Curt Bailey</h2>
      </>
    );
  };

  const mobileBankingListData = {
    accountName: "heartland-restaurant-list",
    items: [
      {
        id: 1,
        text: "Download and view account information"
      },
      {
        id: 2,
        text: "Track and confirm payment activity"
      },
      {
        id: 3,
        text: "Transfer funds between accounts"
      },
      {
        id: 4,
        text: "Manage Line of Credit and Loan payments"
      },
      {
        id: 5,
        text: "Initiate Repetitive Wire transactions"
      }
    ]
  };
  const MobileBankingContent = () => {
    return (
      <>
        <h2>Commercial Mobile Banking</h2>
        <h4 className="text-success font-weight-semi-bold">
          Download the WAFD Treasury Mobile app for a secure and convenient way to manage your WAFD Treasury Prime and
          WAFD Treasury Express commercial accounts.
        </h4>
        <List {...mobileBankingListData} />
        <div className="row">
          <div className="col-auto mb-3 mb-lg-0">
            <MobileAppDownloadBtn
              idName="apple-store"
              storeBtn="apple"
              url="https://apps.apple.com/us/app/wafd-treasury-mobile/id1343834928"
              specialBtn="Commercial Mobile Banking"
            />
          </div>
          <div className="col-auto">
            <MobileAppDownloadBtn
              idName="google-play-store"
              storeBtn="google"
              url="https://play.google.com/store/apps/details?id=com.obs.android.olm.washfed.prod&hl=en_US"
              specialBtn="Commercial Mobile Banking"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Treasury Management</h1>
        <h3 className="mb-0">
          From online and mobile banking to remote deposits, we offer a wealth of services for your convenience. We will
          help you create efficiency - whether that saves time or money.
        </h3>
      </section>
      <StretchedLinkCards {...cardsData} />
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: <VimeoVideoFrame {...treasuryPrimeVideoData} />,
          content: <VideoContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage>
        {{
          image: (
            <>
              <div className="row">
                <div className="col-12 col-md-6">
                  <StaticImage
                    src="../../../images/wafd-treasury-mobile-01-500.png"
                    alt="A screenshot of the WAFD Treasury Mobile App with open menu for accounts, payments, mobile deposits and more"
                    placeholder="blurred"
                    quality="100"
                    className="w-sm-50 w-md-100 border-radius-12 mb-3"
                  />
                </div>
                <div className="d-none d-md-block col-md-6">
                  <StaticImage
                    src="../../../images/wafd-treasury-mobile-payment-01-500.png"
                    alt="A screenshot of the WAFD Treasury Mobile App showing how to quickly make a payment"
                    placeholder="blurred"
                    quality="100"
                    className="w-sm-50 w-md-100 border-radius-12 mb-3"
                  />
                </div>
              </div>
            </>
          ),
          content: <MobileBankingContent />
        }}
      </TwoColumnSectionWithImage>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default TreasuryManagementServices;
